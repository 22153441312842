export default [
  {
    path: '/',
    component: 'route-home',
    bundle: {
      module: '/src/route-home.js',
      nomodule: '/src/es5/route-home.js',
    },
  },
  {
    path: '/auth/social',
    component: 'route-auth-social',
    bundle: {
      module: '/src/route-auth-social.js',
      nomodule: '/src/es5/route-auth-social.js',
    },
  },
  {
    path: '/auth',
    component: 'route-auth',
    bundle: {
      module: '/src/route-auth.js',
      nomodule: '/src/es5/route-auth.js',
    },
  },
  {
    path: '/auth/:form',
    component: 'route-auth',
    bundle: {
      module: '/src/route-auth.js',
      nomodule: '/src/es5/route-auth.js',
    },
  },
  {
    path: '/claim',
    component: 'route-claim',
    bundle: {
      module: '/src/route-claim.js',
      nomodule: '/src/es5/route-claim.js',
    },
  },
  {
    path: '/claim/:podcastslug',
    component: 'route-claim',
    bundle: {
      module: '/src/route-claim.js',
      nomodule: '/src/es5/route-claim.js',
    },
  },
  {
    path: '/audiogram/episodes/:slug',
    component: 'route-audiogram-episodes',
    bundle: {
      module: '/src/route-audiogram-episodes.js',
      nomodule: '/src/es5/route-audiogram-episodes.js',
    },
  },
  {
    path: '/audiogram/create/:slug',
    component: 'route-create-audiogram',
    bundle: {
      module: '/src/route-create-audiogram.js',
      nomodule: '/src/es5/route-create-audiogram.js',
    },
  },
  {
    path: '/audiogram',
    component: 'route-audiogram',
    bundle: {
      module: '/src/route-audiogram.js',
      nomodule: '/src/es5/route-audiogram.js',
    },
  },
  {
    path: '/audiogram/:slug',
    component: 'route-audiogram',
    bundle: {
      module: '/src/route-audiogram.js',
      nomodule: '/src/es5/route-audiogram.js',
    },
  },
  {
    path: '/dashboard',
    component: 'route-dashboard',
    bundle: {
      module: '/src/route-dashboard.js',
      nomodule: '/src/es5/route-dashboard.js',
    },
  },
  {
    path: '/dashboard/gallery/:tool',
    component: 'route-dashboard-gallery',
    bundle: {
      module: '/src/route-dashboard-gallery.js',
      nomodule: '/src/es5/route-dashboard-gallery.js',
    },
  },
  // Add new route before this line
  // Do not add new route after this line to avoid it overlap with not found or,
  // or custom podcast/episode or /podcast route.
  {
    path: '/offline',
    component: 'route-offline',
    bundle: {
      module: '/src/route-offline.js',
      nomodule: '/src/es5/route-offline.js',
    },
  },
  {
    path: '/404',
    component: 'route-not-found',
    bundle: {
      module: '/src/route-notfound.js',
      nomodule: '/src/es5/route-notfound.js',
    },
  },
  {
    path: '/:podcastslug/:episodeslug',
    component: 'route-episode',
    bundle: {
      module: '/src/route-episode.js',
      nomodule: '/src/es5/route-episode.js',
    },
  },
  {
    path: '/:podcastslug',
    component: 'route-podcast',
    bundle: {
      module: '/src/route-podcast.js',
      nomodule: '/src/es5/route-podcast.js',
    },
  },
];
