import {html,css,LitElement} from 'lit';

class ButtonToggleDark extends LitElement{
    constructor(){
        super();
    }

    connectedCallback(){
        super.connectedCallback();
        this.setTheme();
    }

    static get isDark(){
        const isDark = document.body.className.indexOf('dark')===-1?false:true;
        const isLight = document.body.className.indexOf('light')===-1?false:true;
        if(isLight || isDark)
            return isDark? true:false;
        else
            return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    static get properties(){
        return {
            dark:Boolean
        }
    }
    static get styles(){
        return css`
            :host{
                width:2.5rem;
                height:2.5rem;
                display:grid;
                align-items:center;
                justify-items:center;
                cursor:pointer;
            }
            svg{
                color:var(--highlight-text-color);
                height:100%;
                width:100%;
            } 
            button{
                background:transparent;
                border:none;
                height:100%;
                width:100%;
            }
            
        `;
    }

    setTheme(){
        const theme = window.getComputedStyle(document.body).getPropertyValue('--primary-bg-color');
        document.querySelector('meta[name="theme-color"]').setAttribute("content", theme);
    }

    toggle(){
        let removeClass = 'light';
        let addClass = 'dark';
       if( ButtonToggleDark.isDark){
            removeClass = 'dark';
            addClass = 'light';
       }
       document.body.classList.remove(removeClass);
       document.body.classList.add(addClass);
       this.dark = ButtonToggleDark.isDark;
       this.setTheme();
    }

    render(){
  return this.template;
}

get template(){
        return html`
            <button @click="${this.toggle}">
                ${this.renderIcon()}
            </button>
        `;
    }

    renderIcon(){
        return ButtonToggleDark.isDark?html`
                <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Sunny</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-miterlimit='10' stroke-width='32' d='M256 48v48M256 416v48M403.08 108.92l-33.94 33.94M142.86 369.14l-33.94 33.94M464 256h-48M96 256H48M403.08 403.08l-33.94-33.94M142.86 142.86l-33.94-33.94'/><circle cx='256' cy='256' r='80' fill='none' stroke='currentColor' stroke-linecap='round' stroke-miterlimit='10' stroke-width='32'/></svg>
                `:html`
                <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Moon</title><path d='M160 136c0-30.62 4.51-61.61 16-88C99.57 81.27 48 159.32 48 248c0 119.29 96.71 216 216 216 88.68 0 166.73-51.57 200-128-26.39 11.49-57.38 16-88 16-119.29 0-216-96.71-216-216z' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/></svg>
                `;
    }
}

customElements.define('button-toggle-dark',ButtonToggleDark);