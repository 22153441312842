import { match } from 'path-to-regexp/dist.es2015';

export default class WebRouter {
  constructor(routes, callbacks) {
    if (!Array.isArray(routes)) {
      throw new Error('routes parameter must be an array!');
    }
    this.pageModules = [];
    this.currentRoute = '/';
    // adding routes and merge with not found route.
    this.routes = routes.concat({
      path: '/404',
      component: 'route-404',
      bundle: {
        module: '/src/route-notfound.js',
        nomodule: '/src/es5/route-notfound.js',
      },
    });
    this.notFound = '/404';
    this.activeRoute = null;
    this.beforeNavigate = typeof callbacks.beforeNavigate === 'function' ? callbacks.beforeNavigate : () => {};
    this.onNavigate = typeof callbacks.onNavigate === 'function' ? callbacks.onNavigate : () => {};
    this.afterNavigate = typeof callbacks.afterNavigate === 'function' ? callbacks.afterNavigate : () => {};
    this.initEvent();
  }

  initEvent() {
    window.addEventListener('popstate', () => {
      const link = window.location.pathname;
      // make sure the link not outside the app
      this.goTo(link);
    });
    // handle if a link click and prevent to reload the page
    document.addEventListener('route-changed', (e) => {
      const link = e.detail.href;

      // make sure the link not outside the app
      if (!link.match(/^http/)) {
        const paramIndex = link.indexOf('?');
        const url = link.substr(0, paramIndex < 0 ? link.length : paramIndex);
        this.goTo(url);
      }
    });

    this.routes.forEach((route) => {
      if (typeof route.path === 'undefined' || typeof route.component === 'undefined') {
        throw new Error('Route required path and component properties!');
      }
    });

    this.goTo(window.location.pathname);
  }

  async goTo(url) {
    let notFound = true;
    for (let i = 0; i < this.routes.length; i += 1) {
      const route = this.routes[i];
      const isMatch = match(route.path, { decode: decodeURIComponent });
      if (isMatch(url)) {
        this.setActiveRoute(url, route);
        notFound = false;
        break;
      }
    }

    if (notFound) {
      this.goTo(this.notFound);
    }
  }

  async setActiveRoute(url, route) {
    try {
      await this.beforeNavigate(url, route);
      // Disable this temporary due some pages still contain previous data even the route change

      // if(!(route.path in this.pageModules) &&
      // document.createElement(route.component).constructor === HTMLElement){
      //   this.pageModules[route.path] = await import(route.bundle.module);
      // }

      // Directly import for now, refer to issue above
      const module = await import(route.bundle.module);
      this.activeRoute = route.path;
      this.onNavigate(url, route, module.default);
      this.afterNavigate(url, route, module.default);
    } catch (error) {
      console.trace(error);
      throw new Error(error);
    }
  }
}
