import { html, css, LitElement } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

class AppMessage extends LitElement {
  constructor() {
    super();
    this.duration = 3;
  }

  firstUpdated() {
    this.renderRoot.querySelector('slot').addEventListener('slotchange', () => {
      this.slotChanged();
    });
  }

  static get properties() {
    return {
      info: { type: Boolean },
      error: { type: Boolean },
      warning: { type: Boolean },
      duration: { type: Number },
    };
  }

  slotChanged() {
    this.style.animation = `fadeOut ${this.duration}s`;
    setTimeout(() => {
      this.style.display = 'none';
    }, this.duration * 1000);
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }
      @keyframes fadeOut {
        to {
          opacity: 0;
          display: none;
        }
        60% {
          opacity: 1;
        }
        from {
          opacity: 1;
        }
      }
      #message {
        color: #fff;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem;
      }
      .error {
        background-color: #dc3545;
      }
      .warning {
        background-color: #ffc107;
      }
      .info{
        background-color: #89C587;
      }
    `;
  }

  render() {
    return html`
      <div
        id="message"
        class=${classMap({ error: this.error, warning: this.warning, info: this.info })}
      >
        <slot></slot>
      </div>
    `;
  }
}

customElements.define('app-message', AppMessage);
