import { LitElement, html, css } from 'lit';

export class ComponentOfflineState extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
      }
      .is-component-wrapper {
        background: dc3545;
        margin: 5px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
      }
      h5 {
        color: white;
        margin: 0 10px 0 0;
        text-align: center;
      }
    `;
  }

  render() {
    return ComponentOfflineState.template;
  }

  static get template() {
    return html`
      <div class="is-component-wrapper">
        <h5>You are offline.</h5>
        <img alt="Offline" src="/img/icons/cloud-off.svg" width="24px" />
      </div>
    `;
  }
}
customElements.define('component-offline-state', ComponentOfflineState);
