import {
  LitElement, html, css, render,
} from 'lit';
import { LocalDB } from './lib/LocalDB.js';
import WebRouter from './components/web-router/web-router.js';
import routes from './routes.js';
import Analytics from './components/analytics.js';
import './components/ui/component-offline-state.js';

import { registerSW } from './sw-register.js';
import CONFIG from './app.config.js';
import './components/web-router/a-nav.js';
import './components/ui/app-message.js';
import './components/ui/button-toggle-dark.js';

class TApp extends LitElement {
  static get properties() {
    return {
      router: { type: Object },
      worker: { type: Object },
      // isFreshLibrary: { type: Array }
      page: String,
      isOffline: Boolean,
    };
  }

  constructor() {
    super();
    this.isOffline = false;
    this.db = new LocalDB(CONFIG.LIBRARYDB);
    this.playingEpisode = null;
    window.$isLibraryFresh = true;
    // Analytics

    this.page = '';
    this.pages = [];
    this.analytics = new Analytics(this);
    this.config = CONFIG;
    // eslint-disable-next-line no-undef
    this.isProduction = __isProduction__;
    window.$app = this;
    if (this.isProduction) registerSW();
    this.rAF = null;
    this.addEventListener('info', (e) => {
      this.setMessage('info', e.detail);
    });
    this.addEventListener('error', (e) => {
      this.setMessage('error', e.detail);
    });
    this.addEventListener('warning', (e) => {
      this.setMessage('warning', e.detail);
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this.router = this.initRouter();
  }

  setMessage(type, text) {
    this.message = {
      type,
      text,
    };
    this.requestUpdate();
  }

  get audioPlayer() {
    return this.renderRoot.querySelector('audio-player');
  }

  get fullscreenContainer() {
    return this.renderRoot.querySelector('layout-fullscreen');
  }

  get searchBoxFullscreen() {
    return this.renderRoot.querySelector('component-search-fullscreen');
  }

  get homeMain() {
    return this.renderRoot.querySelector('route-home');
  }

  static renderPage(container, route) {
    let exist = false;
    const routeList = container.children;
    for (let i = 0; i < routeList.length; i += 1) {
      const el = routeList[i];
      if (el.tagName === route.component.toUpperCase()) {
        exist = true;
        el.className = 'active';
        el.setAttribute('active', true);
      } else {
        el.className = '';
        el.removeAttribute('active');
      }
    }
    if (!exist) {
      const component = document.createElement(route.component);
      component.className = 'active';
      component.setAttribute('active', true);
      container.appendChild(component);
    }
  }

  renderNavPage(route) {
    const main = this.renderRoot.querySelector('#main');
    TApp.renderPage(main, route);
  }

  renderFullscreenPage(route) {
    const fullscreen = this.renderRoot.querySelector('layout-fullscreen');
    fullscreen.loading();
    const component = document.createElement(route.component);
    component.className = 'active';
    component.setAttribute('active', true);
    render(component, fullscreen);
    fullscreen.activate();
  }

  static whichBrowserEvent() {
    const el = document.createElement('fakeelement');
    const transitions = {
      transition: 'transitionend',
      OTransition: 'oTransitionEnd',
      MozTransition: 'transitionend',
      WebkitTransition: 'webkitTransitionEnd',
    };
    let transision = null;
    Object.keys(transitions).forEach((key) => {
      if (el.style[key] !== undefined) {
        transision = transitions[key];
      }
    });
    return transision;
  }

  initRouter() {
    // TODO: -  fix how to render the page to containers
    return new WebRouter(routes, {
      onNavigate: (_url, route) => {
        this.renderNavPage(route);
      },
      beforeNavigate: async () => new Promise((resolve) => {
        const layout = this.renderRoot.querySelector('main');
        // console.log(layout);
        if (layout) {
          layout.classList.add('page-exit');
          const transitionEnd = TApp.whichBrowserEvent();
          layout.addEventListener(
            transitionEnd,
            () => {
              resolve(true);
            },
            false,
          );
        } else {
          resolve(true);
        }
      }),
      afterNavigate: async () => {
        // need to requestUpdate because component only
        // ready to select and get the meta after the page get rerender
        await this.requestUpdate();
        const layout = this.renderRoot.querySelector('main');
        // console.log(layout, 'afterNavigate');
        if (layout) {
          layout.classList.remove('page-exit');
          const transitionEnd = TApp.whichBrowserEvent();

          layout.addEventListener(
            transitionEnd,
            () => {
              // console.log('animation done')
            },
            false,
          );
        }
      },
    });
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }
      #container {
        position: relative;
      }

      #main {
        flex-grow: 1;
        padding-bottom: 6rem;
        opacity: 1;
        transition: all 0.05s;
        --background-color: white;
        --layout-header-height: 16rem;
        --layout-header-opacity: 1;
      }
      .page-exit {
        opacity: 0.3;
        transition: all 0.02s;
        --background-color: white;
        --layout-header-height: 16rem;
        --layout-header-opacity: 1;
      }

      #main > * {
        display: none;
        content-visibility: hidden;
      }

      #main > .active {
        display: block;
        animation: slide-left-in 0.2s;
      }

      @supports (content-visibility: hidden) {
        #main > * {
          content-visibility: hidden;
        }

        #main > .active {
          content-visibility: auto;
        }
      }

      .active {
        animation: fade-in 0.3s;
      }

      @keyframes fade-in {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      @keyframes slide-left-in {
        from {
          margin-left: 100vw;
        }
        to {
          margin-left: 0;
        }
      }

      @keyframes slide-left-out {
        from {
          margin-left: 0;
        }
        to {
          margin-right: 100vw;
        }
      }

      @keyframes slide-up {
        from {
          margin-top: 100vw;
        }
        to {
          margin-top: 0;
        }
      }

      @keyframes slide-down {
        from {
          margin-left: 0;
        }
        to {
          margin-top: 100vh;
          display: none;
        }
      }

      #main > .leaving {
        animation: 0.5s fadeOut ease-in-out;
      }
      #main > .entering {
        animation: 0.5s fadeIn linear;
      }
      @keyframes fadeOut {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      #main > .hidden {
        display: none;
      }

      layout-fullscreen {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      layout-fullscreen.loading {
        display: block;
        animation: slide-up 0.2s;
      }

      app-message {
        position: absolute;
        top: 1rem;
        right: 1rem;
        left: 1rem;
        z-index: 9;
      }
      @media only screen and (min-device-width: 1024px) {
        nav,
        audio-player {
          left: 5rem;
          right: 5rem;
          max-width: 45rem;
        }
        nav {
          border-radius: 1.75rem;
          background-color: var(--primary-bg-color);
          bottom: 1rem;
        }
        audio-player {
          bottom: 5.5rem;
          border-radius: 2rem;
        }
      }
    `;
  }

  renderAppMessage() {
    return this.message
      ? html`<app-message
          ?error=${this.message.type === 'error'}
          ?warning=${this.message.type === 'warning'}
          ?info=${this.message.type === 'info'}
          >${this.message.text}</app-message
        >`
      : '';
  }

  render() {
    return this.template;
  }

  get template() {
    return html`
      <div id="container" class="is-app-wrapper">
        ${this.isOffline ? TApp.renderOfflineComponent() : ''}
        ${this.renderAppMessage()}
        <div id="main"></div>
      </div>
    `;
  }

  static renderOfflineComponent() {
    return html` <component-offline-state></component-offline-state> `;
  }

  async saveActivity(key, value) {
    try {
      await this.db.put('activity', value, key);
    } catch (error) {
      this.dispatchEvent(
        new CustomEvent('error', {
          detail: error,
          bubbles: true,
          composed: true,
        }),
      );
    }
  }

  async handleOffline() {
    this.isOffline = !navigator.onLine;
    this.requestUpdate();
  }

  async handleOnline() {
    this.isOffline = !navigator.onLine;
    this.requestUpdate();
  }

  async handleLocalStore(e) {
    const { type, data } = e.detail;
    if (Array.isArray(data)) {
      const list = [];
      for (const iterator of data) {
        list.push(this.db.put(type, iterator, iterator['id  ']));
      }
      await Promise.all(list);
    } else {
      await this.db.put(type, data, data.id);
    }
  }

  // updating playing episode activity
  async updatePlaying() {
    const now = new Date().getTime();
    const { audioPlayer } = this;
    if (
      !this.playingEpisode
      || this.playingEpisode.id === Number.parseInt(audioPlayer.episodeId, 10)
    ) {
      this.playingEpisode = {
        id: audioPlayer.episodeId,
        lastPlaying: 0,
      };
    }

    if (
      (now - this.playingEpisode.lastPlaying) / 1000
      > CONFIG.PLAYING_TIMER_INTERVAL
    ) {
      // push to GA
      const detail = {
        action: 'playing-audio',
        episodeId: this.playingEpisode.id,
        podcastName: audioPlayer.podcast,
        episodeTitle: audioPlayer.title,
        currentTime: audioPlayer.currentTime,
        duration: Math.floor(audioPlayer.duration),
      };

      this.dispatchEvent(
        new CustomEvent('action', {
          detail,
          bubbles: true,
          composed: true,
        }),
      );
      this.playingEpisode.lastPlaying = now;
      this.saveActivity(this.playingEpisode.id, this.playingEpisode);
    }
  }

  static isActive(route) {
    const paramIndex = route.indexOf('?');
    const path = route.substr(0, paramIndex < 0 ? route.length : paramIndex);
    return window.location.pathname === path;
  }
}

customElements.define('t-app', TApp);
