import { Workbox, messageSW } from 'workbox-window';
import CONFIG from './app.config.js';
import { LocalDB } from './lib/LocalDB.js';

export const registerSW = async () => {
  if ('serviceWorker' in navigator) {
    let registration;
    const resetCaches = ['static-resources'];

    const wb = new Workbox('/sw.js');
    const runskipWaiting = (event) => {
      wb.addEventListener('controlling', (event) => {
        window.location.reload();
      });

      if (registration && registration.waiting) {
        // Send a message to the waiting service worker,
        // instructing it to activate.
        // Note: for this to work, you have to add a message
        // listener in your service worker. See below.
        messageSW(registration.waiting, { type: 'SKIP_WAITING' });
      }
    };

    wb.addEventListener('waiting', runskipWaiting);
    wb.addEventListener('externalwaiting', runskipWaiting);

    wb.addEventListener('activate', (event) => {
      event.waitUntil(async () => {
        const db = new LocalDB(CONFIG.CACHEDB);
        return Promise.all([
          new Promise((resolve) => {
            const clearing = db.podcastStore.clear();
            clearing.onerror = () => console.error(clearing.error);
            clearing.onsuccess = () => resolve(true);
          }),
          new Promise((resolve) => {
            const clearing = db.episodeStore.clear();
            clearing.onerror = () => console.error(clearing.error);
            clearing.onsuccess = () => resolve(true);
          }),
          new Promise((resolve) => {
            const clearing = db.tagStore.clear();
            clearing.onerror = () => console.error(clearing.error);
            clearing.onsuccess = () => resolve(true);
          }),
        ]);
      });
    });

    wb.register().then(r => registration = r);
  }
};
