export default class Analytics{
    constructor(app){
        window.dataLayer = window.dataLayer || [];
        this.app = app;
        this.init();
    }

    async post(type,content_id,action){
        const baseurl = this.app.config.API_URL;
        const options = {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({
                type: type,
                content_id: content_id,
                action: action
            })
        };
        return await fetch(`${baseurl}/analytics`,options);
    }

    init(){
        //track page navigation
        this.app.addEventListener('navigation', (e)=>{ 
            console.log('navigation',e.detail);
            gtag('event','page_view',{
                'page': e.detail.url,
                'title': e.detail.title
              });
         }, false);

        //track any user action
        this.app.addEventListener('action',async (e)=>{
            if(gtag){
                switch (e.detail.action) {
                    case 'playing-audio':
                        //disable temporary internal analytics report
                        //this.post('episode',e.detail.episodeId,'play');
                        gtag('event', 'playing_audio', {
                            episodeId: e.detail.episodeId,
                            podcastName:e.detail.podcastName,
                            episodeTitle: e.detail.episodeTitle,
                            currentTime: e.detail.currentTime,
                            duration: e.detail.duration
                        });
                        break;
                    case 'content-liked':
                        //only send analytics if it's liked
                        if(e.detail.liked===true){
                            if(e.detail.type ==='episode')
                                gtag('event', 'episode_liked', {
                                    episodeId: e.detail.id,
                                    episodeTitle:e.detail.title
                                });
                            else if(e.detail.type ==='podcast')
                                gtag('event', 'podcast_liked', {
                                    podcastId: e.detail.id,
                                    podcastTitle:e.detail.title
                                });
                        } else if(e.detail.liked===false){
                            if(e.detail.type ==='episode')
                                gtag('event', 'episode_disliked', {
                                    episodeId: e.detail.id,
                                    episodeTitle:e.detail.title
                                });
                            else if(e.detail.type ==='podcast')
                                gtag('event', 'episode_disliked', {
                                    podcastId: e.detail.id,
                                    podcastTitle:e.detail.title
                                });
                        }
                        break;
                    default:
                        break;
                }
            }
        }, false);
    }

}